.active-header{
    color: #ff5b60 !important;
    font-weight: bold;
}

.header-text{
    color: inherit; 
}
.header-main{
    box-shadow: 0 12px 6px 0px #bebebe;
    border-bottom: 1px solid #bebebe;
}
.menu-popup-open{
    position:absolute;
    right:0;
    top:0;
    background-color:#eee;
    height: 100%;
    z-index: 9;
}
.menu-popup-open a{
    line-height: 45px;
}
.menu-popup-close{
    position: absolute;
    right: 0;
    top: 0;
    background: #ff6368;
    line-height: 30px;
    width: 34px;
    height: 34px;
    color: #fff;
    font-weight: bold;
    border-bottom-left-radius: 14px;
    text-align: center;
}