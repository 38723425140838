.contact_localtion {
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    padding: 30px;
    position: relative;
}
.contact_localtion h4 {
    font-size: 18px;
    font-family: "Nunito";
    color: #484848;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 20px;
}
.contact_localtion p {
    font-size: 14px;
    font-family: "Nunito";
    color: #484848;
    line-height: 1.714;
    margin-bottom: 20px;
}

/* //form */
.form_grid h4 {
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 20px;
}
.form_grid p {
    font-size: 14px;
    line-height: 1.714;
    margin-bottom: 30px;
}
form {
    display: block;
    margin-top: 0em;
}
.form_grid {
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    padding: 30px;
}
.form_grid .contact_form button {
    border-radius: 8px;
    background-color: #ff5a5f;
    -webkit-box-shadow: 0px 1px 4px 0px rgb(255 90 95 / 30%);
    -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
    -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
    box-shadow: 0px 1px 4px 0px rgb(255 90 95 / 30%);
    font-size: 16px;
    font-family: "Nunito";
    color: #ffffff;
    font-weight: bold;
    line-height: 1.2;
    height: 50px;
    width: 200px;
}
.contact_form_social_area{
    padding-left: initial;
}
.contact_form_social_area li {
    background-color: #f7f7f7;
    border-radius: 8px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    width: 45px;
}
.list-inline-item:not(:last-child) {
    margin-right: .5rem;
}
.list-inline-item {
    display: inline-block;
}
.contact_form_social_area li a {
    font-size: 14px;
    color: #484848;
    line-height: 1.2;
}