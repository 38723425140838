.imageStyles{
    border-radius: 5px;
    opacity: .6;
    width: 100%;
    object-fit: cover;
}
.card{
    border: 1px solid rgb(255 255 255) !important;
    background-color:none !important;
}
.card-body p{
    font-size: 18px;
    font-family: "Nunito";
    color: #484848;
    font-weight: bold;
    line-height: 1.2;
}
.imgParentDiv{
    background-color: #1d293e;
    border-radius: 5px;
}