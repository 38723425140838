.hline {
    overflow: hidden;
    text-align: center;
  }
  
  .hline:before,
  .hline:after {
    background-color: #e3e3e3;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
   
  }
  
  .hline:before {
    right: 0.5em;
    /* margin-left: 100%; */
  }
  
  .hline:after {
    left: 0.5em;
    margin-right: -50%;
  }
  .fbbtn{
    width: 98%;
    margin-left: 10px;
    height: 50px;
    border-color: #506dab !important ;
    background-color: #fff;
    border-radius: 10px !important;
    color: #506dab;

  }
  .fbbtn:hover{
      background-color: #506dab;
      color: #fff;
  }
  .gbbtn{
    width: 98%;
    height: 50px;
    margin-left: 10px;
    border-color: #dd4b39 !important ;
    background-color: #fff;
    border-radius: 10px !important;
    color: #dd4b39;

  }
  .gbbtn:hover{
    background-color: #dd4b39;
    color: #fff;
}
.logbtn{
    width: 100%;
    border-radius: 10px !important;
    height: 50px;
    background-color: #ff5a5f;
    color: #fff;
    cursor: pointer;
   
}
.logbtn:focus{
    
    outline: none;
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
     -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
          box-shadow: inset 0px 0px 5px #c1c1c1;
}
.logbtn:hover{
    background-color: #fff  !important;
    color: #ff5a5f;
    border-color: #ff5a5f;
   
}

.modalradius{
    border-radius: 20px;
}
.fminput {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    outline: none;
}

  


.has-search .form-control-feedback {
    position: absolute;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    pointer-events: none;
    color: #aaa;
    padding-left: 320px !important;

}