.secone-banner-bgImg{
    background-attachment: scroll;
    background-image: url("../../../../images/background/bg-homecare.jpg");
    background-position: center;
    background-repeat: no-repeat;
    height: 450px;
    padding: 60px 0;
position: relative;
background-size: cover;

}
.bg-secom{
    z-index: 99;
}
.secone-banner-bgImg::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #454444c4;
}
.secone-banner-bgImg .banner-layer{
    padding: 75px 0;
    position: relative;

}



.secone-banner-bgImg .ssp_btn{
    
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    border-radius: 8px;
    color: #484848;
    font-size: 16px;
    font-family: "Nunito";
    font-weight: bold;
    height: 60px;
    line-height: 1.2;
    width: 200px;
    
}