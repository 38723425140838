.gbhrefcolor{
    text-align: center;
    color:#dd4b39;
}
.gbhrefcolor:hover{
    color:#fff;
    text-decoration: none;
}
.fbhrefcolor{
    text-align: center;
    color:#506dab;
}
.fbhrefcolor:hover{
    color:#fff;
    text-decoration: none;
}