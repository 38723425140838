.search{
    display: flex;
    height: 68px;
    margin: 0 auto 30px;
    padding: 0 15px;
    position: relative;
    width: 97%;
    background-color: #ffffff;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
}
@media only screen and (max-width: 767.98px){
    .search{
        display: inline-table;
    }
}
