.theme-box-three .layer-color{
    border-radius: 0px 0px 10px 10px;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    background: -moz-linear-gradient(270deg, rgba(255,255,255,0.01) 50%, rgb(29 41 62 / 81%) 100%); /* ff3.6+ */
    background: -webkit-linear-gradient(270deg, rgba(255,255,255,0.01) 50%, rgb(29 41 62 / 81%) 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(270deg, rgba(255,255,255,0.01) 50%, rgb(29 41 62 / 81%) 100%); /* opera 11.10+ */
    background: -ms-linear-gradient(270deg, rgba(255,255,255,0.01) 50%, rgb(29 41 62 / 81%) 100%); /* ie10+ */
    background: linear-gradient(180deg, rgba(255,255,255,0.01) 50%, rgb(29 41 62 / 81%) 100%); /* w3c */
}
.theme-box-three .booking-btn{
    position: absolute;
    top: 26px;
    right: 0px;
    z-index: 99;
}
.theme-box-three .booking-btn a{
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}