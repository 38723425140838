.sec-round-frame{
    width: 50px;
    height: 50px;
    display: inline-block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
}
.active-submenu{
    box-shadow: 0px 0px 0px 2px #ff5b60;

}
.sec-round-frame::after{
    /* background: #73AD21;
    content: '';
    border-radius: 50%;
    width: 86px;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    box-shadow:0 -10px 27px 8px #a3a3a3;
    margin: auto;
    right: 0; */
}
.sec-round-card-frame{
position: relative;
/* margin-bottom: 10px; */
}
.sub-cat-title{
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
}