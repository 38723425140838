.image-layer{
    border-radius: 0px 0px 10px 10px;
    bottom: 0;
    height: 244px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    background: -moz-linear-gradient(270deg, rgba(255,255,255,0.01) 0%, rgba(29,41,62,1) 100%); /* ff3.6+ */
    background: -webkit-linear-gradient(270deg, rgba(255,255,255,0.01) 0%, rgba(29,41,62,1) 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(270deg, rgba(255,255,255,0.01) 0%, rgba(29,41,62,1) 100%); /* opera 11.10+ */
    background: -ms-linear-gradient(270deg, rgba(255,255,255,0.01) 0%, rgba(29,41,62,1) 100%); /* ie10+ */
    background: linear-gradient(180deg, rgba(255,255,255,0.01) 0%, rgba(29,41,62,1) 100%); /* w3c */
}
.details{
    padding-left: 20px;
    text-align: left;
    bottom: 20px;
    left: 0;
    position: absolute;
    right: 0;
}
.img-section{
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
.booking{
    position: absolute;
    top: 26px;
    right: 0px;
}
.booking a{
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}
@media only screen and (max-width: 767.98px){
    .image-layer{
        /* height: 100%; */
    }
}