.round-frame img{
    bottom: 0;
    left: 0;
    margin: 0 auto;
    right: 0;
    position: absolute;
}

.round-frame{
    background-color: #f7f7f7;
    border-radius: 50%;
    height: 200px;
    margin: 0 auto 20px;
    position: relative;
    width: 200px;
}
.round-card-frame{
position: relative;
margin-bottom: 30px;
}