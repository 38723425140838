
@import url(https://fonts.googleapis.com/css?family=Nunito:400,400i,600,700&amp;display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cursor-pointer{cursor: pointer;}
.react-multi-carousel-list{
  width: 100% !important ;
}
.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
[data-theme="dark"] .site-layout-content {
  background: #141414;
}
.login-form-style{    
  border-radius: 2%;
  box-shadow: 8px 0px 16px #f6f6f6;
}
.reg-form-style{    
  border-radius: 2%;
  box-shadow: 8px 0px 16px #f6f6f6;
  margin-top: 2em;
}
/** Kumar **/
.page-CustomerRooms .ant-collapse-content-box{
  padding: 0 !important;
}
.page-CustomerRooms .ant-collapse-header{
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.page-CustomerRooms .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  padding-top: 4px !important ;
}
.customer-info-model-view{
  display: none;
  width: 100px;
}
.customer-info-model:hover + .customer-info-model-view{
  display: block;
    background-color: #fff;
    position: absolute;
    width: 300px;
    z-index: 99;
    -webkit-box-shadow: 0 2px 8px rgb(0 0 0 / 15%); 
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    border-radius: 4px;
    top: 116%;
    transition: width 60s;
    transition-delay: 1s;
}
.main-rooms-scroll-bar img{
  width: 20px;
}
.main-rooms-scroll-bar{
  overflow: auto;
}
/* Gowthami styles */
.mb40 {
  margin-bottom: 40px !important;
}
.main-title h2 {
  color: #484848;
  font-family: "Nunito";
  line-height: 1.2;
  margin-bottom: 10px;
  margin-top: 0;
}
.main-title p {
  font-size: 16px;
  font-family: "Nunito";
  color: rgb(119, 119, 119);
  line-height: 1.2;
  margin-bottom: 0;
}
.pl15{
  padding-left: 15px;
}
.pl55{
  padding-left: 45px;
}
.pr2em{
  padding-right: 2.5em;
}
.pb2em{
  padding-bottom: 2em;
}
.pb5em{
  padding-bottom: 5em;
}
.mt80 {
  margin-top: 80px;
}
.bg-color{
  background-color: #f7f7f7;
}
.main-title p a {
  color: #484848 !important;
  font-weight: bold;
  line-height: 1.5;
}